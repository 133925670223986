/*
{"email":"tarjeism@gmail.com","verified":true,"auth0id":"auth0|5c90e35cf272482eb3d3f511","userid":393,"subscription":{"status":"Active","hasPending":false,"pendingSubscriptionStatus":"None","validThrough":"2019-05-31T16:16:43","lastFour":"PPAL","expiration":"NA/NA"},"settings":{"downloadLimit":10000,"maxShownRatingKey":"m"}}"
*/

import { Address } from './Address';

export type SubscriptionStatusType =
    'Pending'
    | 'Active'
    | 'Canceled'
    | 'Expired'
    | 'Past Due'
    | 'Failed'
    | 'None'
    | 'SoftConfirmed'
    | 'Admin';

class SubscriptionInfo {
    status: string | SubscriptionStatusType;
    isActive: boolean;

    // Inkypen
    validThrough?: string;
    steamId?: string;
    lastFour: string;
    brand: string;
    expiration: string;
    willRenew: boolean;
    billingResponsible?:"Steam"|"Inkypen"|"Nintendo"|"Coupon";//Apple,GooglePlay

    paymentProviderName?: string;
    paymentProviderId?: number;
    customerId?: string;
}

class AccountSettings {
    downloadLimit: number;
    maxShownRatingKey: string;
}

class Purchase {
    comicID: number;
    productID: string;
}

class AccountInfo {
    email: string;
    userid: number;
    roles: string[];
    verified?: boolean;
    subscription: SubscriptionInfo;
    settings: AccountSettings;
    purchases: Purchase[];
    useBillingAddressForShipping?: boolean;
    defaultBillingAddress?: Address;
    defaultShippingAddress?: Address;


    constructor() {
        this.subscription = new SubscriptionInfo();
        this.settings = new AccountSettings();
    }
}


// Signup
export enum SignupResponseStatus {
    UnknownError = 0,
    Successful = 200,
    TenantError = 400,
    InvalidEmail = 406
}

export { SubscriptionInfo, AccountSettings, AccountInfo, Purchase };
