import { ItemOrder, OrderedPublishable, Publishable } from './';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import update from 'immutability-helper';
import Log from '../../Kodansha/Services/Logger';
import { DisplayOptionValue } from '../../store/DashboardProductsStore';
import { ApiModel } from '../../API/InkyAPI';

class PublishManager<T extends Publishable> {

  protected tokenSource: CancelTokenSource = Axios.CancelToken.source();

  removeItem: (cancelTokenSource: CancelTokenSource, id: number) => Promise<AxiosResponse<any>>;
  protected publishItem: (cancelTokenSource: CancelTokenSource, id: number) => Promise<AxiosResponse<any>>;
  protected scheduleItem: (cancelTokenSource: CancelTokenSource, item: T) => Promise<AxiosResponse<any>>;
  unPublishItem: (cancelTokenSource: CancelTokenSource, id: number) => Promise<AxiosResponse<any>>;
  alterItems: (cancelTokenSource: CancelTokenSource, items: T[]) => Promise<AxiosResponse<any>>;
  fetchItems: (cancelTokenSource: CancelTokenSource) => Promise<T[]>;
  addItem: (cancelTokenSource: CancelTokenSource, item: T) => Promise<AxiosResponse<any>>;
  fetchItem: (cancelTokenSource: CancelTokenSource, id: number) => Promise<T>;
  batchPublish: (IDs: number[], scheduleDate?: Date, scheduleType?: DisplayOptionValue, abortSignal?: AbortSignal) => Promise<ApiModel<any>>;
  batchUnPublish: (IDs: number[], abortSignal?: AbortSignal) => Promise<ApiModel<any>>;

  async fetch(): Promise<T[]> {
    try {
      return await this.fetchItems(this.tokenSource);
    } catch(error) {
      Log.debug("Error: " + error);
      return [];
    }
  }

  async fetchSingleItem(id: number): Promise<T> {
    return await this.fetchItem(this.tokenSource, id);
  }

  hasChanges(items: T[]): boolean {
    return items.some(x => x.hasChanged());
  }

  anyToPublish(items: T[]): boolean {
    return items.some(x => x.isToUnPublish());
  }

  addNew(items: T[], newItem: T): T[] {
    //items.push(newItem);
    return [newItem, ...items];
  }

  discardAllChanges(items: T[]): T[] {
    Log.debug("discarding...");
    const unAddedItems = items.filter(item => !item.isToAdd());
    return unAddedItems.map(item => {
      if(item.hasChanged()) {
        item.copyFromOriginal();
      }
      return item;
    });
  }

  async saveChanges(items: T[]): Promise<T[]> {
    const toAdd = []; //May change.
    const toRemove = [];
    const noAddOrRemove = [];

    const toAlter = [];
    const toUnPublish = [];
    const toPublish = [];
    const toSchedule = [];

    for(let i = 0; i < items.length; i++) {
      const item = items[i];
      Log.debug(`status = ${item.status} old = ${item.original.status}`);
      if(item.isToAdd()) {
        Log.debug(`Adding item ${item.original.status}`);

        //If item has been added and removed without saving in-between, ignore adding item
        if(item.status === Publishable.STATUS_REMOVED)
          noAddOrRemove.push(item);
        else
          toAdd.push(item);
      }
      else if(item.isToRemove()) {
        Log.debug(`Removing item ${item.original.status}`);
        toRemove.push(item);
      }else if (item.isToRestore()){
        toUnPublish.push(item);
      }
      else {
        noAddOrRemove.push(item);

        if(item.hasChanged()) {
          toAlter.push(item);
        }
        if(item.isToUnPublish()) {
          Log.debug(`Adding item to Unpublish ${item}`);
            toUnPublish.push(item);
        }
        else if(item.isToPublish()) {
          toPublish.push(item);
        }
        else if(item.isToSchedule()) {
          toSchedule.push(item);
        }
      }
    }


    await this.addAllAndPushToPublishListIfApplicable(toAdd, toPublish); //Mutates array by replacing items.
    Log.debug(`Added. now remove ${toRemove.length} items`);
    await this.removeAll(toRemove);
    Log.debug(`Removed. now alter ${toAlter.length} items`);
    await this.alterAll(toAlter);
    Log.debug(`Altered. now Publishing ${toPublish.length} items`);
    await this.publishAll(toPublish);
    Log.debug(`Published. now un-publishing ${toUnPublish.length} items`);
    await this.unPublishAll(toUnPublish);
    Log.debug(`UnPublished. now scheduling ${toUnPublish.length} items`);
    await this.scheduleAll(toSchedule);
    Log.debug("Successfully saved!");
    return [...toAdd, ...noAddOrRemove];
  }

  async addAllAndPushToPublishListIfApplicable(items: T[], toPublishItems: T[]): Promise<void> {
    for(let i = 0; i < items.length; i++) {
      const item: T = items[i];

      const resp = await this.addItem(this.tokenSource, item);
      try {
        const newId = resp.data.response.id;
        items[i] = update(item as Publishable, {id: {$set: newId}}) as T; //Hints here are necessary for the typescript linter to compile.

        //If new entry and 'publish' is checked, add to publish list
        if (items[i].status == Publishable.STATUS_PUBLISHED)
          toPublishItems.push(items[i]);
      } catch (error) {
        Log.error("Could not get the response ID!");
        Log.error(error);
      }
    }
  }

  async removeAll(items: T[]): Promise<void> {
    for(let i = 0; i < items.length; i++) {
      const item = items[i];
      await this.removeItem(this.tokenSource, item.id);
    }
  }

  async remove(item: T): Promise<void> {
    await this.removeItem(this.tokenSource, item.id);
  }

  async alterAll(items: T[]): Promise<void> {
    if(items.length>0) {
      await this.alterItems(this.tokenSource, items);
    }
  }

  async publishAll(items: T[]): Promise<void> {
    for(let i = 0; i < items.length; i++) {
      const item = items[i];
      await this.publishItem(this.tokenSource, item.id);
    }
  }

  async scheduleAll(items: T[]): Promise<void> {
    for(let i = 0; i < items.length; i++) {
      const item = items[i];
      await this.scheduleItem(this.tokenSource, item);
    }
  }


  async publish(item: T) {
    await this.publishItem(this.tokenSource, item.id);
  }

  async unPublishAll(items: T[]): Promise<void> {
    for(let i = 0; i < items.length; i++) {
      const item = items[i];
      Log.debug(`Unpublishing ${item.id}`);
      await this.unPublishItem(this.tokenSource, item.id);
    }
  }

  async unPublish(item: T) {
    await this.unPublishItem(this.tokenSource, item.id);
  }

}

class OrderedPublishManager<T extends OrderedPublishable> extends PublishManager<T> {
  reOrderItems: (cancelTokenSource: CancelTokenSource, items: ItemOrder[]) => Promise<AxiosResponse<ItemOrder[]>>;

  async saveChanges(items: T[]): Promise<T[]> {
    const toOrder = items.filter(x=> !x.isToRemove() && !x.isToAdd() && x.hasChangedOrder());
    if(toOrder.length > 0) {
      const reOrderItems = toOrder.map(value => {
           return value.getItemReOrder();
      });
      await this.reOrderItems(this.tokenSource, reOrderItems);
    }
    return await super.saveChanges(items);
  }

  hasChanges(items: T[]): boolean {
    return items.some(x => x.hasChanged() || x.hasChangedOrder());
  }

  discardAllChanges(items: T[]): T[] {
    const discarded = super.discardAllChanges(items);
    discarded.forEach(item => {
      if(item.hasChangedOrder()) {
        item.copyFromOriginal();
      }
    });

    return discarded;
  }
}

export {PublishManager, OrderedPublishManager};
