export enum DashGlobalContentType {
    Header = 'Header',
    Footer = 'Footer',
    Style = 'Style',
}

export interface DashGlobalContentDTO {
    id?: number;
    type?: DashGlobalContentType;
    enabled?: boolean;
    content?: string;
}


export class DashGlobalContent {
    private static _reactKey = 0;
    readonly reactKey: string;

    original?: DashGlobalContent;
    id?: number;
    type?: DashGlobalContentType;
    enabled?: boolean;
    content?: string;

    constructor() {
        this.reactKey = `${DashGlobalContent._reactKey++}`;
    }

    static create(options: {
        id?: number;
        type?: DashGlobalContentType;
        enabled?: boolean;
        content?: string;
    }): DashGlobalContent {
        const original = Object.assign(new DashGlobalContent(), options);
        return Object.assign(new DashGlobalContent(), original, { original });
    }

    static fromApi(dto: DashGlobalContentDTO): DashGlobalContent {
        return DashGlobalContent.create(dto);
    }

    toApi(): DashGlobalContentDTO {
        return { id: this.id, type: this.type, enabled: this.enabled, content: this.content };
    }

    hasChanged(): boolean {
        return !!this.original && !this.equals(this.original);
    }

    equals(other: DashGlobalContent): boolean {
        return this.id === other.id
            && this.type === other.type
            && this.enabled === other.enabled
            && this.content === other.content;
    }

    revert(): DashGlobalContent {
        const original = this.original;
        return Object.assign(new DashGlobalContent(), original, { original });
    }

    merge(options: {
        id?: number;
        type?: DashGlobalContentType;
        enabled?: boolean;
        content?: string;
    }): DashGlobalContent {
        return Object.assign(new DashGlobalContent(), this, options);
    }

    clone(): DashGlobalContent {
        return Object.assign(new DashGlobalContent(), this);
    }
}
